// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-press-js": () => import("../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-video-js": () => import("../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

